import {
    SimpleGrid,
    Box,
    Card,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import SendToGroups from './SendToGroup';
import SendToSpecificUsers from './SendToSpecificUsers';

export default function PushNotification() {

    const { isOpen: isSendToGroupOpen, onOpen: onSendToGroupOpen, onClose: onSendToGroupClose } = useDisclosure();
    const { isOpen: isSendToSpecificUsersOpen, onOpen: onSendToSpecificUsersOpen, onClose: onSendToSpecificUsersClose } = useDisclosure();

    return (
        <>
            <SimpleGrid columns={[1, 2, 2, 3]} spacing='4'>
                <Box w='100%' alignItems='center' boxShadow={'xl'}>
                    <Card bg={'primary.50'} p='8' w='100%' alignItems={'center'} h='100%'>
                        <Text className='custom' fontSize='md' color='primary.900'>Send to All Users</Text>
                    </Card>

                </Box>
                <Box cursor='pointer' onClick={() => onSendToGroupOpen()} w='100%' alignItems='center' boxShadow={'xl'}>
                    <Card bg={'primary.50'} p='8' w='100%' alignItems={'center'} h='100%'>
                        <Text className='custom' fontSize='md' color='primary.900'> Send To Groups</Text>
                    </Card>

                </Box>
                <Box cursor='pointer' onClick={() => onSendToSpecificUsersOpen()} w='100%' alignItems='center' boxShadow={'xl'}>
                    <Card bg={'primary.50'} p='8' w='100%' alignItems={'center'} h='100%'>
                        <Text className='custom' fontSize='md' color='primary.900'> Send To Specific Users</Text>
                    </Card>
                </Box>
            </SimpleGrid >
            <SendToGroups isOpen={isSendToGroupOpen} onClose={onSendToGroupClose} />
            <SendToSpecificUsers isOpen={isSendToSpecificUsersOpen} onClose={onSendToSpecificUsersClose} />
        </>
    )
}


