import {
    Card,
    Container,
    HStack,
    Text,
    SimpleGrid,
    VStack,
    Image,
    Stack
} from '@chakra-ui/react';
import React from 'react';
import { FaCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Dashboard() {

    return (
        <>
            <Container maxWidth='9xl'>

                <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing={4}
                >
                    <Link to={'/user-management'}>
                        <Card mt='5' boxShadow={'lg'}>
                            <HStack width='100%' justifyContent='space-between' p='5'>
                                <VStack w={'100%'} alignItems={'center'}>
                                    <Image src='/user_management.png' h={100} />
                                    <Text fontWeight={600} className='custom' fontSize='xl' color='primary.900'>User Management</Text>
                                </VStack>
                            </HStack>
                        </Card>
                    </Link>

                    <Link to={'/subscription-plan'}>
                        <Card mt='5' boxShadow={'lg'}>
                            <HStack width='100%' justifyContent='space-between' p='5'>
                                <VStack w={'100%'} alignItems={'center'}>
                                    <Image src='/subscription_plan.png' h={100} />
                                    <Text fontWeight={600} className='custom' fontSize='xl' color='primary.900'>Subscription Plan</Text>
                                </VStack>
                            </HStack>
                        </Card>
                    </Link>
                    <Link to={'/content-management'}>
                        <Card mt='5' boxShadow={'lg'}>
                            <HStack width='100%' justifyContent='space-between' p='5'>
                                <VStack w={'100%'} alignItems={'center'}>
                                    <Image src='/content_management.png' h={100} />
                                    <Text fontWeight={600} className='custom' fontSize='xl' color='primary.900'>Content Management</Text>
                                </VStack>
                            </HStack>
                        </Card>
                    </Link>

                </SimpleGrid>

                <Card mt={8}>
                    <VStack>
                        <Stack
                            w={'100%'}
                            direction={{ base: 'column', md: 'column', lg: 'row' }}
                            justifyContent={'space-between'}
                            px={8}
                        >
                            <VStack alignItems={'start'} spacing={0}>
                                <Text fontWeight='500' fontSize='xl'>Reports & Analytics</Text>
                                <Text fontWeight='400' fontSize='sm' color='grey.200'>No of users enrolled and got matched in 2024</Text>
                            </VStack>

                            <HStack spacing={4} mt={{ base: 6, md: 6, lg: 0 }}>
                                <HStack>
                                    <FaCircle color='#40ABC5' />
                                    <Text>New users</Text>
                                </HStack>
                                <HStack>

                                    <FaCircle color='#E2E2E2' />
                                    <Text>Matched users</Text>
                                </HStack>
                            </HStack>
                        </Stack>

                        <Image src='/bar_graph.png' mt={8} h={{ lg: 200 }} />
                    </VStack>
                </Card>

                <Text fontSize={'xs'} mt={6} textAlign={{lg:'end'}}>
                    Copyright © 2024 Forrest All rights reserved.
                </Text>
            </Container>
        </>
    )
}
